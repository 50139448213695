
import React from 'react';
import Header from './components/Header';
import TelescopSection from './components/TelescopSection';
import Features from './components/Features';
import Process from './components/Process';
import CarouselComponent from './components/CarouselComponent';
import Accuracy from './components/Accuracy';
import Technology from './components/Technology';
import TeamSection from './components/TeamSection';
import Collaboration from './components/Collaboration';
import Footer from './components/Footer';

const App: React.FC = () => {
    return (
        <div className="App">
            {/* <Header /> */}
            <TelescopSection />
            <div className='backgroundRadius' >
              <div className='componentsContainer' >
                <Features />
                <Process />
              </div>
            </div>
            <Accuracy />
            <CarouselComponent />
            <Technology />
            <TeamSection />
            <Collaboration />
            <Footer />
        </div>
    );
}

export default App;

