
import React from 'react';
import '../css/features.css';

const Collaboration: React.FC = () => {
    return (
        <section className="features-container componentsContainer second-boxes ">
            <div className="featureContainer">
                <div className="feature text-left">
                    <h2>COLLABORATION WITH THE UNIVERSITY</h2>
                    <p className="feature-text second-text-height color-text">
                        The project was developed in close cooperation with experts and graduates of the Faculty of Electrical Engineering and Computer Science at the Slovak University of Technology in Bratislava, mainly specialists in the latest technologies in artificial intelligence and automation.
                    </p>
                </div>
            </div>
            <div className="featureContainer position-bottom">
                <div className="feature">
                    <h2>TAX OFFICE</h2>
                    <p className="feature-text text-left second-text-height color-text">
                        The company TAX OFFICE Hennel & Králik, s.r.o. has been operating on the market since 2001. Thanks to our many years of experience in tax consulting, we are a reliable partner. Let’s go on a joint journey with the TAX PILOT.
                    </p>
                </div>            
            </div>
            <div className="featureContainer">
                <div className="feature third-container">
                    <h2>LET'S DO IT</h2>
                    <p className="feature-text text-left second-text-height color-text">
                        Are you ready to streamline your tax question processing? Contact us today to find out how Tax Pilot can simplify your work. Leave routine matters to us and let's focus on what's truly important.
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Collaboration;
