
import React from 'react';

import '../css/footer.css';

const Footer: React.FC = () => {
    return (
        <header className="footer">
            <div className="navbar-footer-info">
                <div className="text-info">www.art4.sk</div>
                <div className="text-info">+421 905 120 000</div>
                <div className="text-info last-info">info@art4.sk</div>
            </div>
        </header>
    );
}

export default Footer;
