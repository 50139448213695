
import React from 'react';

import '../css/accuracy.css';

const Accuracy: React.FC = () => {
    return (
        <section className="section-text accuracy">
            <div className='section-content'>
                <h2>ACCURACY OF SOURCES</h2>
                <p className='text'>Our platform includes questions related to EU legislation and 
                    individual member states to provide comprehensive solutions. 
                    The system supports communication in most world languages. 
                    Peter Hennel guarantees discretion and confidentiality between 
                    the client and advisor because security is the highest priority.
                </p>
            </div>
        </section>
    );
}

export default Accuracy;
