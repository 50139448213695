
import React from 'react';

import '../css/accuracy.css';

const Technology: React.FC = () => {
    return (
        <section className="section-text technology">
            <div className='section-content' >
                <div className='textBox'>
                    <h2>TECHNOLOGY</h2>
                    <p>The input question in the form of a vector is assigned by a 
                        semantic search engine to a set of the most relevant snippets 
                        from a vector database of verified sources. The question with 
                        sources is processed by a language model and a resulting answer 
                        is generated with citations to specific documents (RAG principle). 
                        The answer generation is repeated several times, with the best one 
                        being edited and approved by a human taxation expert. Experts 
                        also continuously prepare sample questions and answers suitable 
                        for storage in the database, thereby expanding the resource pool 
                        and improving the system through use. Similarly, the system is 
                        improved through the enhancement of language models and the 
                        gradual progression of algorithms for evaluating vector similarities. 
                        Ultimately, our system combines the speed and logic of the most advanced 
                        AI technologies with the experience and precision of human experts.
                    </p>
                </div>

                <div className='textBox'>
                    <h2>TAX PILOT AS AN INVESTMENT<br/> OPPORTUNITY</h2>
                    <p>The input question in the form of a vector is assigned by a 
                        semantic search engine to a set of the most relevant snippets 
                        from a vector database of verified sources. The question with 
                        sources is processed by a language model and a resulting answer 
                        is generated with citations to specific documents 
                        (RAG principle). The answer generation is repeated several 
                        times, with the best one being edited and approved by a 
                        human taxation expert. Experts also continuously prepare 
                        sample questions and answers suitable for storage in the 
                        database, thereby expanding the resource pool and improving 
                        the system through use. Similarly, the system is improved 
                        through the enhancement of language models and the gradual 
                        progression of algorithms for evaluating vector similarities. 
                        Ultimately, our system combines the speed and logic of 
                        the most advanced AI technologies with the experience and 
                        precision of human experts.
                    </p>
                </div>
            </div>
            <div className='technologyTitle'>
                <h1>BECOME A PART OF THE DIGITAL<br/> 
                    REVOLUTION IN TAX ADVISORY TODAY.
                </h1>
            </div>
        </section>
    );
}

export default Technology;
