
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/carouselComponent.css';

// const images = [
//     { src: '/images/bike.png', alt: 'Astronaut' },
//     { src: '/images/boxer.png', alt: 'Cyclist' },
//     { src: '/images/handshake.png', alt: 'Handshake' },
//     { src: '/images/knight.png', alt: 'Knight' },
//     { src: '/images/cosmonaut.png', alt: 'Cosmonaut' }
// ];

const CarouselComponent: React.FC = () => {
//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 3,
//         slidesToScroll: 1,
//         nextArrow: <SampleNextArrow />,
//         prevArrow: <SamplePrevArrow />,
//         responsive: [
//             {
//                 breakpoint: 640,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 }
//             }
//         ]
//     };
    

    return (
        // <div className="carousel-container">
        //     <Slider {...settings}>
        //         {images.map((image, index) => (
        //             <div key={index} className="carousel-slide">
        //                 <img src={image.src} alt={image.alt} className="carousel-image" />
        //             </div>
        //         ))}
        //     </Slider>
        // </div>

         <div className="images-container">
         <div className="imageSectionFourth">
             <img src="/images/handshake.png" alt="process" />
         </div>
         <div className="imageSectionFourth imgHiddenKnight">
             <img src="/images/cosmonaut.png" alt="process" />
         </div>
         <div className="imageSectionFourth imgHiddenLabak">
             <img src="/images/boxer.png" alt="process" />
         </div>
     </div>
    );
}

// const SampleNextArrow = (props: any) => {
//     const { className, style, onClick } = props;
//     return (
//         <div className={`${className} custom-arrow arrow-container`} onClick={onClick}>
//             <div className='arrow next-arrow'></div>
//         </div>
//     );
// }

// const SamplePrevArrow = (props: any) => {
//     const { className, style, onClick } = props;
//     return (
//         <div className={`${className} custom-arrow arrow-container`} onClick={onClick}>
//             <div className='arrow previous-arrow'></div>
//         </div>
//     );
// }

export default CarouselComponent;

