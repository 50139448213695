
import React from 'react';

import '../css/features.css';

const Features: React.FC = () => {
    return (
        <section className='features-container first-boxes' >
            <div className="featureContainer">
                <div className="feature text-left">
                    <h2>QUICK RESPONSES, <br/>
                         ACCURATE RESULTS. TAX ADVISORY WITHIN REACH</h2>
                    <p className='feature-text first-text'>A personal virtual assistant ready to answer your taxation and 
                        accounting questions 24/7. How to file tax returns or how to 
                        identify and utilize tax incentives and deductions available to 
                        your company to maximize profitability and competitiveness? 
                        You've certainly been in a situation where you suspected 
                        the answer to your question but weren't 100% sure 
                        if the legislation had changed. It's important to have a reliable 
                        source of help at hand. We have harnessed AI to optimize 
                        the acquisition of the necessary resources to meet our taxation 
                        and accounting needs.</p>
                </div>
            </div>
            <div className="featureContainer position-bottom">
                <div className="feature">
                    <h2>WHEN TO USE TAX<br/> PILOT? </h2>
                    <p className='feature-text'>Do you need to find out how to optimize your tax obligations or 
                        do you have a specific question related to your tax duties? 
                        Simply turn to TAX PILOT, enter your question into the computer 
                        and in an instant we will generate the accurate answer guaranteed 
                        by our experts in tax advice and accounting from TAX OFFICE 
                        Hennel and Králik. </p>
                </div>
            </div>
            <div className="featureContainer ">
                <div className="feature text-left third-container">
                    <h2>MOVING TOGETHER<br/> TOWARDS THE FUTURE</h2>
                    <p className='feature-text third-text'>We are entering the era of digital transformation, which is changing 
                        the way people approach taxation and accounting issues. Having quality 
                        and verified data sources is key to the functioning of our system. 
                        The guarantor is Peter Hennel, a renowned tax advisor and lawyer 
                        with 30 years of experience, who has joined forces with the best 
                        and together we have acquired the highest quality resources.</p>
                </div>
            </div>
        </section>
    );
}

export default Features;
