
import React from 'react';
import '../css/TeamSection.css';

const TeamSection: React.FC = () => {
    return (
        <section className="team-section">
            <div className="team-image">
                <img src="/images/team.png" alt="The best team" />
            </div>
            <div className="team-content">
                <h2>THE BEST TEAM</h2>
                <p>We present to you our team of experts who are behind the development 
                    of the TAX PILOT platform.</p>
                <ul>
                    <li>Peter Hennel - Certified expert in tax advisory and lawyer</li>
                    <li>Dušan Králik - Expert in HR and payroll</li>
                    <li>Pavol Jelenek - Data specialist</li>
                    <li>Marek Vančo - Innovation specialist</li>
                    <li>Anton Blaščák - AI expert</li>
                    <li>Jaroslav Šujan - Front end developer</li>
                    <li>Ján Jakub Špuro - Back end developer</li>
                </ul>
                <p>
                    Our team's dynamism and drive enable us to perceive things from 
                    new perspectives and bring a fresh approach to problem-solving. 
                    Our team is fueled by the ambition to achieve the best results. 
                    We are determined to deliver products that are not only innovative 
                    but also contribute to European development and prosperity. We are 
                    excited for the journey ahead, which is united by innovation and 
                    success. We believe that transparency, open communication, and 
                    expert knowledge are key to successful product development and 
                    implementation.
                </p>
            </div>
        </section>
    );
}

export default TeamSection;
