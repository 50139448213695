
import React from 'react';

import '../css/TelescopSection.css';

const TelescopSection: React.FC = () => {
    return (
        <section className="hero-section">
            <div className="hero-content">
                <p>Your personal digital advisor for taxes <br/>
                    and accounting, based on the synergy <br/> 
                    of AI and human knowledge and practice.</p>
                <h1 className='heroTitle' >TAX PILOT</h1>
            </div>
            <div className="image">
                <div className="telescop-image"></div>
            </div>
        </section>
    );
}

export default TelescopSection;
